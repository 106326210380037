import { Accordion, AccordionSummary, Typography, AccordionDetails, FormControl, FormControlLabel, Radio, RadioGroup, IconButton } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Icon, Row, Space, theme, Translate } from 'ui';
import { DeliveryType } from './MessageDialog';
import MessagesDateTimePicker from './MessagesDateTimePicker';
import { getTitleForDeliveryType } from 'utils/messageUtils';
import useStore from 'store'
import { addDays, getDay, format, differenceInCalendarDays, isWithinInterval, addYears, parseISO, isAfter, isBefore } from 'date-fns'
import { isGroupOrganizer } from 'utils/roles';
import { GroupType } from 'utils/types';



const getDateFromNumber = (day: number) => {
  switch (day) {
    case 0: return 'common:sunday'
    case 1: return 'common:monday'
    case 2: return 'common:tuesday'
    case 3: return 'common:wednesday'
    case 4: return 'common:thursday'
    case 5: return 'common:friday'
    case 6: return 'common:saturday'
  }
}

export function formatExpirationDate(date: string, t: any) {
  let d = date
  // if (typeof d  === 'string' || d  instanceof String) {
  //   d = parseISO(d)
  // }
  return isWithinInterval(d, { start: new Date(), end: addDays(new Date(), 7) }) ? `${t('messagesDateTimeSelector:message_valid_until_day')}  ${t(getDateFromNumber(getDay(d)))}` : `${t('common:until')} ${format(d, 'dd.MM.yyyy')}`
}
export const returnTextDescription = (diffrenceInDays: number, finishIn: string, t: any) => {
  if (diffrenceInDays > 1800) {
    return t('messagesDateTimeSelector:message_valid_forever')
  }
  switch (diffrenceInDays) {
    case 1:
      return t('messagesDateTimeSelector:message_valid_one_day')
    case 2:
      return t('messagesDateTimeSelector:message_valid_two_days')
    case 7:
      return t('messagesDateTimeSelector:message_valid_for_one_week')
    case 14:
      return t('messagesDateTimeSelector:message_valid_for_two_weeks')
    case 31:
      return t('messagesDateTimeSelector:message_valid_for_month')
    case 365:
      return t('messagesDateTimeSelector:for_year')
    default:
      return `${finishIn ? formatExpirationDate(finishIn, t) : ''}`
  }
}
// interface MessagesDateTimeAccordionProps {
//   expanded: boolean
//   deliveryType: DeliveryType
//   customExpiryDateTime: Date | null
//   onExpandedChanged: (isExpanded: boolean) => void
//   onDeliveryTypeChange: (deliveryType: DeliveryType) => void
//   onDateTimeChange: (date: Date | null) => void
// }

const StyledRadio = styled(Radio)`
  &.MuiRadio-root {
    width: 20px;
    height: 20px;
    color: ${theme.color.blue};
    margin: 6px;
    padding: 0;
  }
`

const IconContainer = styled.div`
  display: flex;
  max-width: 36px;
  flex: 1;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  background: ${theme.color.blue};
  margin: 0 6px 0 0;
  body[dir='rtl'] & {
    margin: 0 0px 0 6px;
  }
`

const StyledAccordion = styled(Accordion)`
 
  &.MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
&.MuiAccordion-rounded:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
`

const StyledInnerAccordion = styled(Accordion)`
  &.MuiAccordion-root {
    margin: 0;
  }
  &.MuiAccordion-root:before {
    background: none;
  }
  & > .MuiAccordionSummary-root{
    display: none;
  }
 `

const StyledAccordionSummary = styled(AccordionSummary)`
&.MuiAccordionSummary-root{
    min-height: 34px;
    max-height: 34px;
    height: 34px;
    border-radius: 10px;
    border: solid 1px ${theme.color.blue};
    background-color: ${theme.color.lightBlue};
    padding-left: 0;
    padding-right: 12px;
    body[dir='rtl'] & {
      padding-left: 12px;
      padding-right: 0;
    }
    overflow: hidden;
    & p {
      text-align: center;
      font-size: ${theme.fontSize.small};
      color: ${theme.color.blueText};
      line-height: 1;
    }
  }

`

const AccordionSummaryContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
`

const Aaa = styled(AccordionDetails)`
  padding: 0;
`

const MessagesDateTimeAccordion: FC<MessagesDateTimeAccordionProps> = () => {
  const { t, i18n } = useTranslation('messagesDateTimeSelector')
  const [title, setTitle] = useState('')
  const { newMessage } = useStore()
  const updateMessage = false
  const [radiosExanded, setRadiosExanded] = useState(true)
  const [customTimingExanded, setCustomTimingExanded] = useState(false)
  const [showExpirationDatePicker, setShowExpirationDatePicker] = useState(false)
  const [showScheduleDatePicker, setShowScheduleDatePicker] = useState(false)
  const [hasSetEndDateManually, setHasSetEndDateManually] = useState(updateMessage ? true : false)
  const [deliveryTimeExpanded, setDeliveryTimeExpanded] = useState(false)
  const [deliveryType, setDeliveryType] = useState<DeliveryType>(DeliveryType.ImmediateWeek)
  const [customExpiryDateTime, setCustomExpiryDateTime] = useState<Date | null>(null)
  const [schedule, setSchedule] = useState<Date | null>(newMessage?.newMessage?.schedule || null)
  const isAfterSchedule = React.useMemo(() => isAfter(new Date(), new Date(newMessage?.newMessage?.schedule || 0)), [])
  const showSchedule = false

  useEffect(() => {
    const currentExp = newMessage?.newMessage?.expiry
    const currentSchedule = newMessage?.newMessage?.schedule
    const generatedDeliveryType = currentExp ? DeliveryType.CustomDateTime : DeliveryType.ImmediateWeek

    if (currentExp && !currentSchedule) {
      const finishIn = new Date(currentExp)
      let numberBetweenDays = differenceInCalendarDays(finishIn, newMessage?.newMessage?.created ? new Date(newMessage?.newMessage?.created) : new Date())

      if (numberBetweenDays === 2) {
        setDeliveryType(DeliveryType.Immediate2Days)
        setTitle(t`titleImmediate2Days`)
      }
      else if (numberBetweenDays === 7) {
        setDeliveryType(DeliveryType.ImmediateWeek)
        setTitle(t`titleImmediatelyWeek`)
      }
      else if (numberBetweenDays === 14) {
        setDeliveryType(DeliveryType.Immediate2Weeks)
        setTitle(t`titleImmediately2Weeks`)
      }
      else if (numberBetweenDays === 31) {
        setDeliveryType(DeliveryType.ImmediateMonth)
        setTitle(t`titleImmediatelyMonth`)
      }
      else if (numberBetweenDays == 365 || numberBetweenDays == 366) {
        setDeliveryType(DeliveryType.ImmediateYear)
        setTitle(t`titleImmediatelyYear`)
      } 
      else {
        setDeliveryType(DeliveryType.CustomDateTime)
      }
    } else {
      setDeliveryType(generatedDeliveryType)
    }
    setDeliveryTimeExpanded(false)
    // newMessage.updateExpiryDate(currentExp ? currentExp : expiryTime.immediateWeek)
    setCustomExpiryDateTime(currentExp ? new Date(currentExp) : null)
    if (!isAfterSchedule && currentSchedule) {
      setSchedule(new Date(currentSchedule))
      //setTitle(getTitleForDeliveryType(generatedDeliveryType, new Date(currentExp), new Date(currentSchedule), t))
    }
    else if (updateMessage) {
      const numOfDays = differenceInCalendarDays(currentExp, Date.now())
      //setTitle(`${t('message_valid_until2')} ${returnTextDescription(numOfDays, new Date(currentExp), t)}`)
    } else {
      //setTitle(getTitleForDeliveryType(deliveryType, customExpiryDateTime, schedule, t))
    }
  }, [])

  useEffect(() => {

    return () => {
      setRadiosExanded(true)
      setCustomTimingExanded(false)
      setShowExpirationDatePicker(false)
      setCustomExpiryDateTime(null)
    }
  }, [])

  // useEffect(() => {

  //     // setTitle(getTitleForDeliveryType(deliveryType, customExpiryDateTime, t))
  //     newMessage.updateExpiryDate(customExpiryDateTime)


  // }, [customExpiryDateTime])


  useEffect(() => {
    if (showExpirationDatePicker) {
      setRadiosExanded(false)
    }
  }, [showExpirationDatePicker])

  useEffect(() => {
    if (deliveryType === DeliveryType.CustomDateTime) {
      setSchedule(new Date())
      // newMessage.updateScheduleDate(newMessage.currentUserMessage?.message.schedule || new Date())
      // const newExpiry = addDays(new Date(), 7)
      // setCustomExpiryDateTime(newExpiry)
      // newMessage.updateExpiryDate(newExpiry)
      setCustomTimingExanded(true)
    } else {
      // setSchedule(null)
      // newMessage.updateScheduleDate(null)
      setCustomTimingExanded(false)
    }

  }, [deliveryType])

  const toggleCustomTimingExpanded = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    setCustomTimingExanded(prev => !prev)
  }

  const handleExpanded = (expanded: boolean) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setDeliveryTimeExpanded(isExpanded)
  }

  const toggleRadiosAccordion = () => {
    setRadiosExanded(prev => !prev)
  }

  const resolveExpiryTime = (date, newType) => {
    const expiryTypes = { 
      immediate2Days: addDays(date, 2),
      immediateWeek: addDays(date, 7),
      immediate2Weeks: addDays(date, 14),
      immediateMonth: addDays(date, 31),
      immediateYear: addYears(date, 1),
      immediateNoExpiry: addYears(date, 6),
      customDateTime: undefined
    }

    return expiryTypes[newType]
  }

  const handleDeliveryType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newType: DeliveryType = (event.target as HTMLInputElement).value as DeliveryType
    setDeliveryType(newType)

    if (newType != DeliveryType.CustomDateTime) {
      newMessage.updateScheduleDate(updateMessage ? new Date() : null)

      newMessage.getExpiryDateByApi().then(date => {
        newMessage.updateExpiryDate(resolveExpiryTime(date, newType))
        setCustomExpiryDateTime(resolveExpiryTime(date, newType))
      })
    }

    setTitle(getTitleForDeliveryType(newType, customExpiryDateTime, schedule, t))

  };
  const handleExpiryDateChanged = (date: Date) => {
    setCustomExpiryDateTime(date)
    newMessage.updateExpiryDate(date)
    setTitle(getTitleForDeliveryType(deliveryType, date, schedule, t))
    setHasSetEndDateManually(true)
  };

  const handleSetShowExpirationDatePicker = (bool: boolean) => {
    setShowExpirationDatePicker(bool)
    setShowScheduleDatePicker(false)
  }
  const handleSetShowScheduleDatePicker = (bool: boolean) => {
    setShowScheduleDatePicker(bool)
    setShowExpirationDatePicker(false)
  }

  return (
    <div>
      <StyledAccordion expanded={deliveryTimeExpanded} elevation={0} onChange={handleExpanded(true)}>
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          IconButtonProps={{ edge: i18next.dir() === 'rtl' ? 'start' : 'end' }}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <AccordionSummaryContent>
            <IconContainer>
              <Icon name='stopWatch' namespace='mekome' size={16} />
            </IconContainer>
            <Typography>{title}</Typography>
          </AccordionSummaryContent>
        </StyledAccordionSummary>
        <AccordionDetails>
          <FormControl>
            <Accordion elevation={0}>
              <RadioGroup
                aria-label="deliveryType"
                name="deliveryType"
                value={deliveryType}
                onChange={handleDeliveryType}
              >
                <StyledInnerAccordion elevation={0} expanded={radiosExanded}>
                  <AccordionSummary />
                  <StyledFormControlLabel value={DeliveryType.Immediate2Days} control={<StyledRadio size={'small'} />} label={<Translate t={t} i18nKey='immediately2Days' />} />
                  <StyledFormControlLabel value={DeliveryType.ImmediateWeek} control={<StyledRadio size={'small'} />} label={<Translate t={t} i18nKey='immediatelyWeek' />} />
                  <StyledFormControlLabel value={DeliveryType.Immediate2Weeks} control={<StyledRadio size={'small'} />} label={<Translate t={t} i18nKey='immediately2Weeks' />} />
                  <StyledFormControlLabel value={DeliveryType.ImmediateMonth} control={<StyledRadio size={'small'} />} label={<Translate t={t} i18nKey='immediatelyMonth' />} />
                  <StyledFormControlLabel value={DeliveryType.ImmediateYear} control={<StyledRadio size={'small'} />} label={<Translate t={t} i18nKey='immediatelyYear' />} />
                </StyledInnerAccordion>
                {false &&
                  <StyledInnerAccordion elevation={0} expanded={customTimingExanded}>
                    <Row>
                      <StyledFormControlLabel value={DeliveryType.CustomDateTime} control={<StyledRadio size={'small'} />} label={<Typography onClick={toggleCustomTimingExpanded}>{t`customTiming`}</Typography>} />
                      {deliveryType === DeliveryType.CustomDateTime && (<IconButton size='small' onClick={toggleRadiosAccordion}>
                        {radiosExanded ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                      )}
                    </Row>
                    <Aaa>
                      <div>
                        {/* {(!isAfterSchedule) && (
                          <MessagesDateTimePicker
                            onValueChanged={handleScheduleDateChanged}
                            value={schedule}
                            showDatePicker={showScheduleDatePicker}
                            setShowDatePicker={handleSetShowScheduleDatePicker}
                            text={t`publication_date`}
                            type='schedule'
                          />
                        )} */}
                        <MessagesDateTimePicker
                          onValueChanged={handleExpiryDateChanged}
                          value={customExpiryDateTime}
                          showDatePicker={showExpirationDatePicker}
                          setShowDatePicker={handleSetShowExpirationDatePicker}
                          text={t`expiration_date`}
                          type='expiration'
                          minDate={addDays(schedule || new Date(), 1)}
                        />
                      </div>
                    </Aaa>
                  </StyledInnerAccordion>
                }
              </RadioGroup>
            </Accordion>
            <Space height={1} />
          </FormControl>
        </AccordionDetails>
      </StyledAccordion>
    </div >
  )
}

export default MessagesDateTimeAccordion
