import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone'
import { observer } from 'mobx-react'
import { Button, Dialog, DialogActions, DialogContent, IconButton, Typography, Tooltip, LinearProgress } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import { Close } from '@material-ui/icons'
import { Icon, Space, theme, Text } from 'ui'
import useStore from 'store'
import { useTranslation } from 'react-i18next'
import MessagesDateTimeAccordion from './MessagesDateTimeAccordion'
import PdfPreview from './PdfPreview'
import VideoPreview from './VideoPreview'
import MessagesHeader from './MessagesHeader'
import WarningDialog from './WarningDialog'
import { FilesType } from 'utils/types'
import RichTextEditor from './RichTextEditor'
import SelectGroup from './SelectGroup'
import SelectCommunity from './SelectCommunity'
import videoDefaultThumbnail from 'assets/video_placeholder01.svg'
import videoDefaultThumbnailLogo from 'assets/logo.png'
import imageCompression from 'browser-image-compression'
import { _GSPS2PDF } from "utils/pdf-compress/worker-init.js"
import { processVideo } from "utils/video-compress/worker-init.js"

const MAX_PDF_SIZE = 5000000
const MAX_VIDEO_SIZE = 30000000
const MIN_SIZE_TO_COMPRESS = 1200000
const IMAGE_MAX_HEIGHT_AND_WIDTH = 800
const MAX_FILE_SIZE_MB = 2

export enum DeliveryType {
  Immediate2Days = 'immediate2Days',
  ImmediateWeek = 'immediateWeek',
  Immediate2Weeks = 'immediate2Weeks',
  ImmediateMonth = 'immediateMonth',
  ImmediateYear = 'immediateYear',
  ImmediateNoExpiry = 'immediateNoExpiry',
  CustomDateTime = 'customDateTime'
}


interface Props { }

const StyledDialog = styled(Dialog)`
  align-self: center;
  justify-self: center;
  .MuiDialog-paperScrollPaper {
  /* min-height: 70vh;
  max-height: 70vh;
  width:1000px; */
  max-height: 85vh;
  border:1px solid white;
  }
`
const DropArea = styled.div`
flex:1;
  padding: 1rem;
  border-radius: 4px;
  color: ${theme.color.lightBlueText};
  background: ${p => p.color + '1A'};
  border: 1px dashed ${theme.color.lightBlueText};
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    text-align: center;
  }
`

const DropzoneIcon = styled(Icon)`
  margin: 1rem 0.4rem;
`

const SendIcon = styled(Send)`
  body[dir='rtl'] & {
    transform: scaleX(-1)
  }
`

const ConfirmButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: ${theme.fontSize.large};
    font-weight: 500;
    min-width: 140px;
    height: 50px;
    margin: 0.5rem;
    background: ${theme.color.confirmButton};
    &:hover {
      background: ${theme.color.darkBlue};
    }
    color: ${theme.color.white};
    &.Mui-disabled {
      opacity: 0.5;
    }
  }

`
const DropAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius:10px;
`

const DropAreaIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Container = styled.div`
 display:flex;
 justify-content:center;
 align-items:center;
 position:relative;
`
const StyledButton = styled(IconButton)`
position: absolute;
    /* top: 30px; */
    left: 10px;
    top:0;
    z-index: 100;
    color:white;
`
const StyledImage = styled.img`
  height:100%;
  width:100%;
  object-fit:contain;
`
const HoverPointer = styled.div`

:hover {
  cursor: pointer;
}
`
const ContainerDialog = styled.div<{ color: string }>`
  background-color:${p => p.color + '1A'};
  max-height: 650px;
  overflow: hidden;
  overflow-y:auto;
`
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const isWebAssemblySupported = () => {
  try {
      if (typeof WebAssembly === "object"
          && typeof WebAssembly.instantiate === "function") {
          const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
          if (module instanceof WebAssembly.Module)
              return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
      }
  } catch (e) {
    return false;
  }

  return false;
};

const LinearIndeterminate = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
       {value == -200 ? <LinearProgress variant='indeterminate'/> : <LinearProgress  value={value} variant='determinate'/>}
    </div>
  );
}

const defaultVideoImage = async () => {
  const response = await fetch(videoDefaultThumbnail);
  const data = await response.blob();
  const metadata = {
    type: 'image/svg+xml'
  };

  return new File([data], "default.svg", metadata);
}

const imageFromFrame = (videoFile, frameTimeInSeconds = 1) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    const video = document.createElement('video')
    const source = document.createElement('source')
    const context = canvas.getContext('2d')
    const urlRef = URL.createObjectURL(videoFile)

    video.style.display = 'none'
    canvas.style.display = 'none'

    source.setAttribute('src', urlRef)
    video.setAttribute('crossorigin', 'anonymous')

    video.appendChild(source)
    document.body.appendChild(canvas)
    document.body.appendChild(video)

    if (!context) {
      console.log(`Couldn't retrieve context 2d`)
      resolve(null)
      return
    }

    video.currentTime = frameTimeInSeconds
    video.load()

    video.addEventListener('loadedmetadata', function () {
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
    })

    video.addEventListener('loadeddata', function () {
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      canvas.toBlob((blob) => {
        if (!blob) {
          resolve(null)
          video.remove()
          canvas.remove()  
          return
        }

        resolve(new File([blob], 'thumbnail', {type: 'image/png'}))
      
        URL.revokeObjectURL(urlRef)
        video.remove()
        canvas.remove()
      }, 'image/png')
    })
  })
}

const RenderRegularMessage = observer(({ handleUpload, openMessageWarning, videoLoading }: { handleUpload: (file: File[]) => void, openMessageWarning: (text: string) => void, videoLoading: boolean }) => {
  const { t } = useTranslation(['MessageDialog'])
  const [videoCompressionProgress, setVideoProgressCompression] = useState(0)
  const [file, setFile] = useState(null)
  const { messages, newMessage, user } = useStore()
  const info = user.userInfo
  const allowSendVideo = info !== null && info.extraData !== null && JSON.parse(info.extraData).sendVideo


  useEffect(() => {
    if (newMessage.newMessage?.files && newMessage.newMessage?.files.length == 2) {
      setFile(newMessage.newMessage?.files[1])
    } else if (newMessage.newMessage?.files && newMessage.newMessage?.files[0]) {
      setFile(newMessage.newMessage?.files[0])
    }
  }, [videoLoading])
 

  return (
    <Container>
      {file === null ? <Dropzone
        onDropRejected={() => openMessageWarning(t`notFotmat`)}
        onDrop={(file) => handleUpload(file, setVideoProgressCompression)}
        multiple={false}
        accept={['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].concat(allowSendVideo ? ['video/mp4'] : [])}
      >
        {({ getRootProps, getInputProps }) => (
          <Tooltip title={t`fileOptions`}><DropArea color={theme.color.dropzoneBlue} {...getRootProps()}>
            <input {...getInputProps()} />
            <DropAreaContent>
              <DropAreaIconsContainer>
                {allowSendVideo && <DropzoneIcon namespace='mekome' name='video' size={32} />}
                <DropzoneIcon namespace='mekome' name='galleryRoundGreen' size={34} />
                <DropzoneIcon namespace='mekome' name='documentRoundBlue' size={34} />
              </DropAreaIconsContainer>
              <Text textcolor={theme.color.communityBlue}>{t`dragFiles`}</Text>
              {videoLoading && <>
                <LinearIndeterminate value={videoCompressionProgress}/>
                <div style={{ color: '#1a90ff' }}>{file?.type === 'VIDEO' ? "מכין וידאו לשליחה" : "מכין קובץ לשליחה"}</div>
              </>}
            </DropAreaContent>
          </DropArea>
          </Tooltip>
        )}
      </Dropzone> :
        <div style={{ flex: 1 }}>
          <StyledButton
            onClick={() => {
              newMessage.clearFiles()
              setFile(null)
              }}>
            <Close style={{ color: 'black' }} />
          </StyledButton>
          <HoverPointer>
            {(file?.type === 'PDF') && <PdfPreview height={900} file={file?.url} />}
            {(file?.type === 'VIDEO') && 
              <VideoPreview  
                thumbnail={newMessage.newMessage?.files && newMessage.newMessage?.files.length == 2 ?newMessage.newMessage?.files[0].url : null}
                widthClass=".rc-md-editor" 
                fileUrl={file?.local ? window.URL.createObjectURL(file?.url) : file?.url} 
                fileType={file?.local ? file?.url?.type : null} 
              />
            }
            {(file?.type === 'IMAGE') &&
              <StyledImage
                onClick={() => messages.openViewerModal('image', file?.local ? URL.createObjectURL(file.url) : file?.url)}
                src={file?.local ? URL.createObjectURL(file.url) : file?.url}>
              </StyledImage>}
          </HoverPointer>
        </div>
      }
    </Container>
  )
});

async function loadPDFData(response, filename) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", response);
    xhr.responseType = "arraybuffer";
    xhr.onload = function () {
      window.URL.revokeObjectURL(response);
      const blob = new Blob([xhr.response], {type: "application/pdf"});
      resolve(new File([blob], filename, {
        type: "application/pdf"
      }));
    };
    xhr.send();
  });
}


async function compressPdf(pdf) {
  if (MIN_SIZE_TO_COMPRESS > pdf.size) {
    return pdf;
  }
  
  const dataObject = { psDataURL: URL.createObjectURL(pdf) };
  const element = await _GSPS2PDF(dataObject)

  return loadPDFData(element, pdf.name)
}

async function compressVideo(inputFile, setVideoProgressCompression) {
  if (MIN_SIZE_TO_COMPRESS > inputFile.size) {
    return inputFile;
  }

  if (!isWebAssemblySupported()) {
    console.log("WebAssembly is not supported");
    return inputFile
  }

  console.log("Start compress action");

  const dataObject = { 
    dataURL: URL.createObjectURL(inputFile), 
    type: inputFile.type 
  };

  const url = await processVideo(dataObject, setVideoProgressCompression)

  const res = await fetch(url);
  const blob = await res.blob();

  window.URL.revokeObjectURL(url);

  return blob as any
}

async function compressImage(file, setVideoProgressCompression) {
  if (MIN_SIZE_TO_COMPRESS > file.size) {
    return file[0];
  }
  
  console.log("Start compress action");

  const blob = await imageCompression(file[0], { 
    maxSizeMB: MAX_FILE_SIZE_MB, 
    maxWidthOrHeight: IMAGE_MAX_HEIGHT_AND_WIDTH,
    onProgress: (progress) => {
      setVideoProgressCompression(progress)
    } 
  })

  return blob
}

const MessageDialog: FC<Props> = () => {
  const { t } = useTranslation(['MessageDialog'])
  const { newMessage, user } = useStore()
  const [openWarning, setOpenWarning] = useState(false)
  const [videoLoading, setVideoLoading] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')
  const allowSendVideo = user.info !== null && !Array.isArray(user.info) && user.info.extraData !== null && JSON.parse(user.info.extraData).sendVideo

  const handleUpload = async (file: File[], setVideoProgressCompression) => {
    if (!file || file.length === 0) {
      return
    }

    const typePdf = ['application/pdf']
    const typeVideo = ['video/mp4']
    const typeImage = ['image/jpeg', 'image/png']

    if (![...typeImage, ...typeVideo, ...typePdf].includes(file[0]?.type)) {
      openMessageWarning(allowSendVideo ? t`notFotmatWithVideo` : t`notFotmat`)
      return
    }

    if (file[0]?.size > MAX_PDF_SIZE && file[0]?.type === 'application/pdf') {
      openMessageWarning(t`fileTooBig`)
      return
    }

    if (file[0]?.size > MAX_VIDEO_SIZE && typeVideo.includes(file[0].type)) {
      openMessageWarning(`גודל מקסימלי של וידאו 30M`)
      return
    }
    
    if (typePdf.includes(file[0].type)) {
      setVideoProgressCompression(-200)
      setVideoLoading(true)
      const compressedPdf = await compressPdf(file[0])
      newMessage.setFiles(compressedPdf, FilesType.PDF, true)
      setVideoProgressCompression(0)
      setVideoLoading(false)
    } else if(typeImage.includes(file[0].type)) {     
      setVideoLoading(true)
      setVideoProgressCompression(0)
      const compressedImage = await compressImage(file, setVideoProgressCompression)
      newMessage.setFiles(compressedImage, FilesType.IMAGE, true)
      setVideoLoading(false)
    } else {
      try {
        
        const thumbnail = await imageFromFrame(file[0])
        
        setVideoLoading(true)
        setVideoProgressCompression(0)
        const comressedFile = await compressVideo(file[0], setVideoProgressCompression)

        if (thumbnail === null) {
          const defaultThumbnail = await defaultVideoImage()
          newMessage.setVideoFiles([
            { url: defaultThumbnail, type: FilesType.IMAGE, local: true },
            { url: comressedFile, type: FilesType.VIDEO, local: true }
          ])
        } else {
          newMessage.setVideoFiles([
            { url: thumbnail, type: FilesType.IMAGE, local: true },
            { url: comressedFile, type: FilesType.VIDEO, local: true }
          ])
        }

        setVideoLoading(false)
      } catch(e) {
        console.error(e)
        const defaultThumbnail = await defaultVideoImage()
        newMessage.setVideoFiles([
          { url: defaultThumbnail, type: FilesType.IMAGE, local: true },
          { url: file[0], type: FilesType.VIDEO, local: true }
        ])
        setVideoLoading(false)
      }
    }
  }

  const handleOk = () => {
    newMessage.createMessage()
  }

  const onClose = () => {
    newMessage.closeMessageDialog()
  }

  const isDisabled = () => {
    if (!newMessage.newMessage.payload.length && !newMessage.newMessage.files) {
      return true
    }

    if ((!newMessage.newMessage.label || 
      !newMessage.newMessage.communities.filter(el => el.status).length) &&
      newMessage.currentMessage == null
    ) {
      return true
    }

    if (videoLoading) {
      return true
    }

    return false
  }


  const openMessageWarning = (text: string) => {
    setOpenWarning(true)
    setWarningMessage(text)

  }

  function handleEditorChange({ html, text }: { html: any, text: string }) {
    if (text !== undefined) {
      newMessage.updateMessagePayload(text)
    }
  }

  return (
    <StyledDialog
      open={newMessage.messageDialogOpen}
      fullWidth
    >
      <MessagesHeader
        textVarient={'black'}
        backColor={theme.color.white}
        secondText={t`messagesNewMessage`}
        mainText={user.userInfo?.name || 'מועצה מקומית גליל עליון'}
        onClose={onClose}
      />
      <ContainerDialog color={theme.color.white}>
        <DialogContent>
          <Space />
          <MessagesDateTimeAccordion />
          <Space />
          <RichTextEditor
            value={newMessage.newMessage.payload}
            onChange={handleEditorChange}
            placeholder={t`messageBody`}
          />
          <Space height={1} />
          <RenderRegularMessage
            videoLoading={videoLoading}
            handleUpload={handleUpload}
            openMessageWarning={openMessageWarning}
          />
          {newMessage.currentMessage == null && 
            <>
            <Space />
            <SelectGroup />
            <Space />
            <SelectCommunity />
          </>}
        </DialogContent>
      </ContainerDialog>
      <DialogActions style={{ backgroundColor: theme.color.white }}>
        <ConfirmButton
          disabled={isDisabled()}
          endIcon={<SendIcon />}
          onClick={handleOk}>{t('app:send')}</ConfirmButton>
      </DialogActions>
      <WarningDialog
        isOpen={openWarning}
        okAction={() => setOpenWarning(false)}
        cancelAction={() => setOpenWarning(false)}
        singleText={warningMessage}
      />

    </StyledDialog>
  )
}

export default observer(MessageDialog)
