import React,{FC} from 'react'
import { Dialog, Button, DialogTitle, DialogActions } from '@material-ui/core'
import { observer } from 'mobx-react'
import {  theme, Text } from 'ui'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components'
import useStore from 'store'

const DialogActionsStyled = styled(DialogActions)`
  width: 100%;
`

const InfoText = styled(Text)`
  font-size:18px;
  font-weight:500;
  text-align:right;
  width:100%;
  color: gray;
`
const DialogTitleStyled = styled(DialogTitle)`
  text-align:right;
  width: 100%;
`
const Container = styled.div`
    display:flex;
    min-width:400px;
    height:200px;
    justify-content:space-around;
    align-items:center;
    flex-direction:column;
    padding:20px;
    word-break: break-word;

`


const DeleteDialog: FC<any> = ({ }) => {
    const { messages } = useStore()
    const { t } = useTranslation('app')

    const singleText = 'לא ניתן לשחזר הודעות שנמחקות, האם למחוק את ההודעה?'

    const cancelAction = () => {
        messages.setDeleteModalOpen(false)
    }

    const okAction = () => {
      messages.deleteMessage()
    }

    return (
        <Dialog open={messages.deleteModalOpen} onClose={cancelAction}>
            <Container>   
              <DialogTitleStyled>
              מחיקת הודעה
              </DialogTitleStyled>
              <InfoText>{singleText}</InfoText>
              <DialogActionsStyled>
                <Button size="large" onClick={cancelAction}>ביטול</Button>
                <Button size="large" style={{backgroundColor:theme.color.dangerRed, color:theme.color.white}} onClick={okAction}>כן, למחוק</Button>
              </DialogActionsStyled>
            </Container>
        </Dialog>
    )
}

export default observer(DeleteDialog)